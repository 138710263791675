<script setup lang="ts">
import { usePartnerPlaylistStore } from '~~/stores/partnerplaylist'

const route = useRoute()

const { state, fetchPartnerPlaylistDetail } = usePartnerPlaylistStore()

fetchPartnerPlaylistDetail({ payload: route.params?.slug })
</script>

<template>
  <div>
    <LazyOrganismsNavbar />

    <div class="kg-content partnerplaylist">
      <LazyModulesPartnerPlaylistOrganismsBanner />
      <div class="container">
        <LazyModulesPartnerPlaylistOrganismsProgressUpdate />

        <LazyModulesPartnerPlaylistMoleculesSectionCourseContinue
          class="my-10"
        />

        <LazyModulesHomeMoleculesDiscovery />

        <div class="mb-12">
          <LazyModulesPartnerPlaylistOrganismsCourses />
        </div>
      </div>
    </div>

    <LazyOrganismsFooter />
  </div>
</template>
